<template>
  <div class="flex flex-col ">
    <Hero />
    <div class="mt-80"> 
      <div v-if="loading" class="w-11/12 mx-auto h-52">
            <div class="flex flex-col">
                <div v-for="(i,index) in 10" :key="index" class="my-10">
                    <skeleton-loader :count="4" width="5%" height="80" class="rounded-md"  :theme="theme" />
                    <skeleton-loader :count="4" width="40%" height="20"  :theme="theme" />
                    <skeleton-loader :count="4" width="50%" height="20" :theme="theme" />
                    <skeleton-loader :count="4"  width="70%" height="20" :theme="theme" />
                </div>
            </div>
        </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero'
import Feeds from '@/components/Feeds'
import SkeletonLoader from '@/components/Skeleton'
import axios from 'axios'
export default {
  name: 'Home',
  components:{
    Hero,
    Feeds,
    SkeletonLoader,
  },
  data() {
    return {
      news: [
    {
        "source": {
            "id": null,
            "name": "Seeking Alpha"
        },
        "author": "SA Transcripts",
        "title": "Thoughtworks Holding, Inc. (TWKS) CEO Guo Xiao on Q2 2022 Results - Earnings Call Transcript",
        "description": "Thoughtworks Holding, Inc. (NASDAQ:NASDAQ:TWKS) Q2 2022 Earnings Conference Call August 15, 2022 8:00 AM ETCompany ParticipantsGuo Xiao - CEO & DirectorErin Cummins - CFOConference Call...",
        "url": "https://seekingalpha.com/article/4534518-thoughtworks-holding-inc-twks-ceo-guo-xiao-on-q2-2022-results-earnings-call-transcript",
        "urlToImage": "https://static.seekingalpha.com/assets/og_image_1200-29b2bfe1a595477db6826bd2126c63ac2091efb7ec76347a8e7f81ba17e3de6c.png",
        "publishedAt": "2022-08-15T18:23:10Z",
        "content": "Thoughtworks Holding, Inc. (NASDAQ:TWKS) Q2 2022 Earnings Conference Call August 15, 2022 8:00 AM ET\r\n Company Participants\r\n Guo Xiao - CEO &amp; Director\r\n Erin Cummins - CFO\r\n Conference Call Part… [+50623 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "STLtoday.com"
        },
        "author": "By Joe Holleman St. Louis Post-Dispatch",
        "title": "Former Post-Dispatch bureau chief joining SIUE Hall of Fame",
        "description": "Terry Ganey will be inducted with nine others on Sept. 23. He was a Pulitzer Prize finalist in 1993 for his uncovering Missouri’s Second Injury Fund scandal.",
        "url": "https://www.stltoday.com/news/local/columns/joe-holleman/former-post-dispatch-bureau-chief-joining-siue-hall-of-fame/article_d03ef7db-9e02-5817-bef7-7d7ec7d48881.html",
        "urlToImage": "https://bloximages.newyork1.vip.townnews.com/stltoday.com/content/tncms/assets/v3/editorial/1/90/190e1c03-66c2-547e-a341-20cdf8542d0a/62fa87eed3faf.image.jpg?crop=212%2C212%2C45%2C38&resize=200%2C200&order=crop%2Cresize",
        "publishedAt": "2022-08-15T18:15:00Z",
        "content": "Time to tip the reporting cap to Terry Ganey, longtime Post-Dispatch reporter and Jefferson City bureau chief.\r\nNext month, Ganey will be inducted into the hall of fame at Southern Illinois Universit… [+2447 chars]"
    },
    {
        "source": {
            "id": "the-verge",
            "name": "The Verge"
        },
        "author": "Jay Peters",
        "title": "A new Lord of the Rings game is in the works from Weta Workshop",
        "description": "Weta Workshop is developing a new video game based on the Lord of the Rings books. Weta is partnering with Take-Two’s Private Division on the upcoming title.",
        "url": "https://www.theverge.com/2022/8/15/23306569/lord-of-the-rings-game-weta-workshop-private-division-take-two",
        "urlToImage": "https://cdn.vox-cdn.com/thumbor/QvmwKlzsgifruFgO2g4x76oER7A=/0x146:2040x1214/fit-in/1200x630/cdn.vox-cdn.com/uploads/chorus_asset/file/9605339/fellowshipofthering.jpg",
        "publishedAt": "2022-08-15T18:11:36Z",
        "content": "Take-Twos Private Division is partnering with Weta on the project\r\nThe game will be based on the books.\r\nImage: Warner Bros.\r\nWeta Workshop, which worked on Peter Jacksons Lord of the Rings movie tri… [+1108 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "Appgefahren.de"
        },
        "author": "Mel",
        "title": "Gurman: Apple weitet Werbeanzeigen-Geschäft massiv aus",
        "description": "In seinem neuesten Power On-Newsletter berichtet der Bloomberg-Redakteur Mark Gurman von Apples Plänen, das eigene Anzeigen-Geschäft aggressiv auszubauen. In der Vergangenheit hatte der Konzern bereits mit der Funktion „App Tracking Transparency“ (ATT) erste …",
        "url": "https://www.appgefahren.de/?p=323103",
        "urlToImage": "https://www.appgefahren.de/wp-content/uploads/2022/03/apple-icon.jpg",
        "publishedAt": "2022-08-15T18:10:30Z",
        "content": "In seinem neuesten Power On-Newsletter berichtet der Bloomberg-Redakteur Mark Gurman von Apples Plänen, das eigene Anzeigen-Geschäft aggressiv auszubauen. In der Vergangenheit hatte der Konzern berei… [+1991 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "STLtoday.com"
        },
        "author": "By Joel Currier St. Louis Post-Dispatch",
        "title": "St. Louis man charged in Carondelet neighborhood double homicide",
        "description": "Qwanzell Watkins was charged with two counts of second-degree murder and two counts of armed criminal action in an Aug. 12, 2022, double homicide in the city's Carondelet neighborhood.",
        "url": "https://www.stltoday.com/news/local/crime-and-courts/st-louis-man-charged-in-carondelet-neighborhood-double-homicide/article_802cb302-dcb9-5527-b598-a586904a12e7.html",
        "urlToImage": "https://bloximages.newyork1.vip.townnews.com/stltoday.com/content/tncms/assets/v3/editorial/8/02/802cb302-dcb9-5527-b598-a586904a12e7/62fa8abd81b34.preview.jpg?crop=1830%2C961%2C0%2C85&resize=1200%2C630&order=crop%2Cresize",
        "publishedAt": "2022-08-15T18:05:00Z",
        "content": "ST. LOUIS  A St. Louis man was charged over the weekend in a double homicide Friday in the citys Carondelet neighborhood.\r\nQwanzell Watkins, 23, of the 5500 block of South 37th Street, was charged Sa… [+2631 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "Sdsu.edu"
        },
        "author": null,
        "title": "Business School Change Up: Aztec Earns Diploma, Then Gets MLB Call | NewsCenter | SDSU - SDSU Newscenter",
        "description": "Righty Troy Melton missed the ‘21 MLB draft but now has a marketing degree under his Tigers baseball belt.",
        "url": "https://newscenter.sdsu.edu/sdsu_newscenter/news_story.aspx?sid=78846",
        "urlToImage": "http://newscenter.sdsu.edu/sdsu_newscenter/images/stories/TROY-MELTON-08152022-main.jpg",
        "publishedAt": "2022-08-15T18:02:30Z",
        "content": "Thursday, August 18, 2022, 10am – 2pm At the SDSU University Library, the intellectual hub of the campus, you will see a state-of-the-art facility that houses a physical collection of over 1.6 millio… [+608 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "allears.net"
        },
        "author": "Shelby Smith",
        "title": "Fake Volcanoes, Cake Castles, and More Disney World Disappointments",
        "description": "We've rounded up some things that YOU were most disappointed by on your first trip to Disney World! Check out what they were here. \nThe post Fake Volcanoes, Cake Castles, and More Disney World Disappointments appeared first on AllEars.Net.",
        "url": "https://allears.net/2022/08/15/fake-volcanoes-cake-castles-and-more-disney-world-disappointments/",
        "urlToImage": "https://allears.net/wp-content/uploads/2022/04/2022-wdw-mk-atmo-stock-cinderellas-castle-2.jpg",
        "publishedAt": "2022-08-15T18:00:20Z",
        "content": "Can you remember your first trip to Disney World?\r\nCinderella Castle\r\nIn addition to being a source of many great memories for lots of guests, there are some things about a vacation in Disney World t… [+7165 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "Elite Daily"
        },
        "author": "Kaitlin Cubria",
        "title": "How 'Bodies, Bodies, Bodies' Covered All Pete Davidson's Tattoos (& Why)",
        "description": "Makeup artist Sarah Graalman explained how and why they covered up all of Pete Davidson's body tattoos in A24's 'Bodies, Bodies, Bodies'.",
        "url": "https://www.elitedaily.com/style/pete-davidson-tattoos-covered-removed-bodies-bodies-bodies",
        "urlToImage": "https://imgix.bustle.com/uploads/image/2022/8/15/29a4f9ba-8996-41e4-aebe-fce3628f4ab3-bcc7da77-790e-4ecb-b91e-1a5ecbe3b0df.jpg?w=1200&h=630&fit=crop&crop=faces&fm=jpg",
        "publishedAt": "2022-08-15T18:00:06Z",
        "content": "Mark my words, there will be history books that focus specifically on Pete Davidsons plethora of tattoos. Among the ink across his body rests an image of the late Ruth Bader Ginsburg, SHAOLIN (an ode… [+2208 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "STLtoday.com"
        },
        "author": "By David Kvidahl | STLhighschoolsports.com",
        "title": "No. 12: St. Mary's wide receiver Hendricks ready to step into the spotlight",
        "description": "St. Mary's wide receiver Chase Hendricks is No. 12 on the Post-Dispatch Super 30 football recruiting countdown.",
        "url": "https://www.stltoday.com/sports/high-school/football/super-30/no-12-st-marys-wide-receiver-hendricks-ready-to-step-into-the-spotlight/article_fed93428-1a85-11ed-8368-1be48650598f.html",
        "urlToImage": "https://bloximages.newyork1.vip.townnews.com/stltoday.com/content/tncms/assets/v3/editorial/4/87/4872c092-1ca5-11ed-8602-4b03e6a0c8d6/61abcfc1b2ff2.image.jpg?crop=1894%2C994%2C0%2C50&resize=1200%2C630&order=crop%2Cresize",
        "publishedAt": "2022-08-15T18:00:00Z",
        "content": "Chase Hendricks didnt get tackled the last six games of his junior season.\r\nYou read that right.\r\nHe. Was. Not. Tackled.\r\nAs outrageous as it might sound, St. Marys football coach Ken Turner and Hend… [+9848 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "Hotukdeals.com"
        },
        "author": "minion00",
        "title": "110° - Anker LC90 Rechargeable 900 Lumens CREE LED Flashlight IP65 £23.99 @ Dispatches from Amazon Sold by AnkerDirect UK",
        "description": "£23.99 - AmazonExcellent and powerful flashlight. I bought a couple in 2017 and they're still going strong.Might come in handy this winter.Info added b...",
        "url": "https://www.hotukdeals.com/deals/anker-lc90-rechargeable-900-lumens-cree-led-flashlight-ip65-ps2399-at-amazon-3981170",
        "urlToImage": "https://images.hotukdeals.com/threads/raw/vC2ks/3981170_1/re/1024x1024/qt/60/3981170_1.jpg",
        "publishedAt": "2022-08-15T17:56:38Z",
        "content": "Note Amazon Prime is increasing from September 15th 2022 - £8.99 per month rolling or £95 per year The following delivery costs are for orders fulfilled by Amazon. Delivery costs charged by third-par… [+1197 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "ESPN"
        },
        "author": "Bill Connelly",
        "title": "AP preseason poll reaction: Projecting who will rise, fall and win it all",
        "description": "With the AP preseason Top 25 out, Bill Connelly shares his thoughts, including the six teams that have a shot at winning it all.",
        "url": "https://www.espn.com/college-football/insider/story/_/id/34403282/college-football-preseason-ap-top-25-poll-reaction-rankings-tell-us-season-ahead",
        "urlToImage": "https://a1.espncdn.com/combiner/i?img=%2Fphoto%2F2022%2F0111%2Fr960163_1296x729_16%2D9.jpg",
        "publishedAt": "2022-08-15T17:54:50Z",
        "content": "In 1950, the Associated Press released a preseason college football poll for the first time. It had been ranking teams, starting midseason, for 14 years, and the venture proved popular enough to expa… [+12621 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "FX Empire"
        },
        "author": "Reuters",
        "title": "Explainer-The enduring impact of fatwas",
        "description": "(Reuters) -   The 1989 fatwa imposed by Iran&#039;s late Ayatollah Ruhollah Khomeini on Salman Rushdie for his novel “the Satanic Verses” has haunted many liberal novelists and thinkers whose writings were also seen as insulting to Islam and the Prophet Moham…",
        "url": "https://www.fxempire.com/news/article/explainer-the-enduring-impact-of-fatwas-1097042",
        "urlToImage": "https://responsive.fxempire.com/width/600/webp-lossy-70.q50/_fxempire_/2022/08/tagreuters.com2022newsml_LYNXMPEI7E0OW1.jpg",
        "publishedAt": "2022-08-15T17:53:12Z",
        "content": "Who implements the death fatwas?\r\nOver the past three decades some Sunni Muslim preachers and jihadi figures with millions of followers have also issued fatwas calling for the deaths of Muslims they … [+2526 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "The Star Online"
        },
        "author": "The Star Online",
        "title": "Explainer-The enduring impact of fatwas",
        "description": "(Reuters) - The 1989 fatwa imposed by Iran's late Ayatollah Ruhollah Khomeini on Salman Rushdie for his novel “the Satanic Verses” has haunted many liberal novelists and thinkers whose writings were also seen as insulting to Islam and the Prophet Mohammad. Re…",
        "url": "https://www.thestar.com.my/news/world/2022/08/16/explainer-the-enduring-impact-of-fatwas",
        "urlToImage": "https://apicms.thestar.com.my/uploads/images/2022/08/16/1699172.jpg",
        "publishedAt": "2022-08-15T17:53:00Z",
        "content": "(Reuters) - The 1989 fatwa imposed by Iran's late Ayatollah Ruhollah Khomeini on Salman Rushdie for his novel the Satanic Verses has haunted many liberal novelists and thinkers whose writings were al… [+4433 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "Daily Beast"
        },
        "author": "Erica Radol",
        "title": "Home Decor, Books, & Jewelry: Everything We’re Buying During the Metropolitan Museum of Art Store’s Sale",
        "description": "Scouted/The Daily Beast/The Met Store.\r\nScouted selects products independently. If you purchase something from our posts, we may earn a small commission.Standing on the steps of New York City’s Metropolitan Museum of Art (the Met), one might think it a holy i…",
        "url": "https://www.thedailybeast.com/met-museum-store-sale",
        "urlToImage": "https://img.thedailybeast.com/image/upload/c_crop,d_placeholder_euli9k,h_1440,w_2560,x_0,y_0/dpr_2.0/c_limit,w_740/fl_lossy,q_auto/v1660585874/the_met_store_sale_sgpdjr",
        "publishedAt": "2022-08-15T17:52:28Z",
        "content": "Scouted selects products independently. If you purchase something from our posts, we may earn a small commission.\r\nStanding on the steps of New York Citys Metropolitan Museum of Art (the Met), one mi… [+2268 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "Yahoo Entertainment"
        },
        "author": "Jennifer Taylor",
        "title": "Back-to-School Shopping: Parents Share Their Hacks",
        "description": "Summer is in its second half, which means it's time to start thinking about sending your kids back to school. If you're like many parents, you might be ready...",
        "url": "https://finance.yahoo.com/news/back-school-shopping-parents-share-175102523.html",
        "urlToImage": "https://s.yimg.com/ny/api/res/1.2/lRxaLzGidY9ff4Kv6.WSGA--/YXBwaWQ9aGlnaGxhbmRlcjt3PTEyMDA7aD02NzU-/https://media.zenfs.com/en/gobankingrates_644/9f33be668482f9608a411c9c9412c6be",
        "publishedAt": "2022-08-15T17:51:02Z",
        "content": "Summer is in its second half, which means its time to start thinking about sending your kids back to school. If youre like many parents, you might be ready for a break from your adorable-yet-energeti… [+5836 chars]"
    },
    {
        "source": {
            "id": "cbc-news",
            "name": "CBC News"
        },
        "author": "CBC News",
        "title": "Military family puts down roots in N.S. with mobile flower shop",
        "description": "Krista Adams and her husband planted a field of flowers on their new property, renovated an old school bus, and have spent the summer driving to farmer's markets in the valley.",
        "url": "https://www.cbc.ca/news/canada/nova-scotia/military-family-puts-down-roots-in-ns-1.6551428",
        "urlToImage": "https://i.cbc.ca/1.6551538.1660583538!/fileImage/httpImage/image.jpg_gen/derivatives/16x9_620/flower-bus-1.jpg",
        "publishedAt": "2022-08-15T17:50:59Z",
        "content": "After moving around the continent for years, a military family is putting roots down, literally, in Nova Scotia's Annapolis Valley.\r\nKrista Adams' husband Brad was posted in Colorado when the two dec… [+2578 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "Minneapolis Star Tribune"
        },
        "author": "ANTHONY IZAGUIRRE and ADRIANA GOMEZ LICON",
        "title": "'Don't Say Gay' law confuses some Florida schools",
        "description": "Some Florida schools have moved library books and debated changing textbooks in response to a law critics call \"Don't Say Gay\" — and some teachers have worried that family pictures on their desks could get them in trouble.",
        "url": "https://www.startribune.com/dont-say-gay-law-confuses-some-florida-schools/600198434/",
        "urlToImage": "https://www.startribune.com/static/img/branding/logos/strib-social-card.png?d=1660570531",
        "publishedAt": "2022-08-15T17:50:13Z",
        "content": "TALLAHASSEE, Fla. Some Florida schools have moved library books and debated changing textbooks in response to a law critics call \"Don't Say Gay\" and some teachers have worried that family pictures on… [+5556 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "PlayStation LifeStyle"
        },
        "author": "Nick Tan",
        "title": "New Lord of the Rings Game in the Works by Take-Two’s Private Division",
        "description": "Take-Two’s Private Division publishing label and WETA Workshop have announced […]\nThe post New Lord of the Rings Game in the Works by Take-Two’s Private Division appeared first on PlayStation LifeStyle.",
        "url": "https://www.playstationlifestyle.net/2022/08/15/new-lord-of-the-rings-game-take-two-private-division-weta-workshop/",
        "urlToImage": "https://www.playstationlifestyle.net/assets/uploads/2022/08/new-lord-of-the-rings-game.jpg",
        "publishedAt": "2022-08-15T17:49:24Z",
        "content": "Take-Twos Private Division publishing label and WETA Workshop have announced a new Lord of the Rings game to be set in Middle-earth. While WETA Workshop is best known for its prop work on The Lord of… [+2106 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "Hotukdeals.com"
        },
        "author": "wadz",
        "title": "111° - Primewire - HDMI 2.1 8k 4k Cable 2M £4.48 sold by CSL-Computer and Fulfilled by Amazon",
        "description": "Primewire - HDMI 2.1 8k 4k Cable 2M £4.48 @ CSL-Computer and Fulfilled by Amazon<br /><br />Model: Experience UHD video and audio in high quality! The Primewire 8K HDMI cable complies with the HDMI 2.1 standard and transmits images in excellent, high resoluti…",
        "url": "https://www.hotukdeals.com/deals/primewire-hdmi-21-8k-4k-cable-2m-ps448-at-csl-computer-and-fulfilled-by-amazon-3981519",
        "urlToImage": "https://images.hotukdeals.com/threads/raw/QEDBQ/3981519_1/re/1024x1024/qt/60/3981519_1.jpg",
        "publishedAt": "2022-08-15T17:47:47Z",
        "content": "Note Amazon Prime is increasing from September 15th 2022 - £8.99 per month rolling or £95 per year The following delivery costs are for orders fulfilled by Amazon. Delivery costs charged by third-par… [+1197 chars]"
    },
    {
        "source": {
            "id": null,
            "name": "Billings Gazette"
        },
        "author": "AP",
        "title": "AP News Summary at 1:47 p.m. EDT",
        "description": "Iran denies involvement but justifies Salman Rushdie attack",
        "url": "https://billingsgazette.com/news/national/ap-news-summary-at-2-17-p-m-edt/article_198b7ac9-4b42-5a8a-b402-895b0dfec94f.html",
        "urlToImage": "https://bloximages.chicago2.vip.townnews.com/billingsgazette.com/content/tncms/custom/image/c72cf8ac-c550-11ea-86bd-3761faee86a6.jpg?resize=600%2C315",
        "publishedAt": "2022-08-15T17:47:01Z",
        "content": "Giulianis lawyers told hes a target of Ga. election probe\r\nATLANTA (AP) Rudy Giulianis lawyer says prosecutors in Atlanta have said Giuliani is a target of their criminal investigation into possible … [+7365 chars]"
    }
],
      loading: false,
    }
  },
  // mounted() {
  //   axios.get(`https://newsapi.org/v2/everything?q=books&from=2022-07-15&sortBy=publishedAt&apiKey=7cc1cb81583e40848c40b32ad061a385`)
  //               .then(response => {
  //                  this.news =  response.data.articles.splice(0, 20)
  //                   this.loading = false
  //                   console.log(this.news)

  //               })
  //               .catch(err=> console.log('error: ' + err.message))
  // },
  
}
</script>
