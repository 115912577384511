<template>
    <div v-for="(item, index) in news" :key="index" class="relative w-7/12 mx-auto pt-20 overflow-auto">
        <div class="">
            <h1 class="text-2xl my-5">{{item.title}}</h1>
            <p class="my-5">{{item.publishedAt}}</p>
            <p>{{item.description}}</p>
        </div>
        <hr class="mt-5"/>
    </div>
</template>

<script>
export default {
    props:['news']
}
</script>

<style scoped>

</style>