import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Auth from '../views/Auth'
import CreateAccount from '../views/CreateAccount.vue'
import SearchResult from '@/components/SearchResult'
const routes = [
  {
    path: '/',
    name: 'index',
    component: Auth
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta:{
      requireAuth: true
    }
  },
  {
    path:'/user-signin',
    name: 'signin',
    component: Auth
  },
  {
    path: '/create-account',
    name: 'signup',
    component: CreateAccount
  },
  {
    path: '/search-results/:query',
    name: 'search',
    component: SearchResult
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next)=> {
  const loggedIn = localStorage.getItem('user')
  if(to.matched.some(record => record.meta.requireAuth) && !loggedIn){
    next('/')
  }
  next()
})

export default router
