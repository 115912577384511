<template>
    <header class="fixed flex z-10 justify-between items-center px-10 bg-secondary h-20 w-full">
        <router-link to="/home" class="flex items-center">
            <img src="../assets/logo.png" width="70" alt="">
            <h3 class="text-white text-xl font-bold mt-7 " >Bibliory</h3>
        </router-link>
            <!-- <img src="" alt=""> -->

        <ul class="flex justify-between space-x-6 text-black">
            <li class="text-white hover:bg-black rounded-md py-2 px-4"> <router-link to="articles"> <i class="fa fa-user-login"></i>&nbsp;  Articles</router-link> </li>
            <li class="text-white hover:bg-black rounded-md py-2 px-4"> <router-link to="articles"> <i class="fa fa-user-login"></i>&nbsp;  Jounals</router-link> </li>
            <li class="text-white hover:bg-black rounded-md py-2 px-4"> <router-link to="articles"> <i class="fa fa-user-login"></i>&nbsp;  Books</router-link> </li>
            <li class="text-white hover:bg-black rounded-md py-2 px-4"> <router-link to="articles"> <i class="fa fa-user-login"></i>&nbsp;  Articles</router-link> </li>
         
        </ul>
    </header>
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>