<template>
        <div class="hero--container fixed z-10 w-full flex justify-center items-center  bg-primary px-10 sm:px-0  sm:pt-10 mt-24  sm:mt-20 ">
      <!-- Search input -->
      <div class="w-full max-w-screen-sm">
        <h1 class="text text-center  text-white text-semibold text-2xl font-bold  sm:text-5xl pb-4">Bibliory online resources</h1>
        <p class="text-white text-base text-bold text-center mb-2">Search over 600,000 E-Resources. Download instantly for your research, or order a scanned copy quickly and easily.</p>
        <div class="relative mb-20">
            <input v-model="query" class="w-full h-12 text-sm outline-none mt-3 border-0 rounded-lg transition-all pl-7 pr-20 focus:border-blue-600" type="text" placeholder="Search  for E-resources here">
            <i class="absolute top-7 text-[#bfc6cd] left-2 fa fa-search"></i>
            <button @click="searchThesis" class="absolute right-2 rounded-lg cursor-pointer transition-all hover:bg-black top-4 h-10 w-16 bg-primary text-white text-sm">Search</button>
        </div>  
      </div>
    </div>
</template>

<script>
import {ref} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import {useStore} from 'vuex'
export default {
    setup () {
        const query = ref('')
        const router = useRouter()
        const route = useRoute()
        const store = useStore()
        function searchThesis() {
            router.push({
                name: 'search',
                params: {
                    query: query.value,
                }
            })
            // store.dispatch('search', query.value)
        }
        return {
            query,
            searchThesis
        }
    }
}
</script>

<style scoped>
    .hero--container {
        height: 48%;

        background: #E64848;
        /* background-image: url('../../public/imgs/left.svg'), url('../../public/imgs/right.svg') ; */
        background-size: 31%, 27%;
        background-repeat: no-repeat;
        background-position: left, right;
    }
     @media (max-width: 480px) {
            .hero--container {
                background-image: none;
            }
        }
</style>