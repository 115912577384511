<template>
    <div class="flex justify-center py-48">
      <div class="bg-white w-[500px] px-10 py-20">
        <form @submit.prevent="login">
                  <p class="mb-4 text-xl font-semibold ">Please login to your account</p>
                  <div class="mb-7">
                    <input
                      type="text"
                      v-model="username"
                      class="form-control block w-full px-3 py-4 text-base font-normal text-gray-700 bg-gray-200 rounded transition ease-in-out m-0 focus:text-gray-700   focus:outline-none "
                      id="exampleFormControlInput1"
                      placeholder="Username"
                      required="true"
                    />
                  </div>
                  <div class="mb-7">
                    <input
                      type="password"
                      v-model="password"
                      class="form-control block w-full px-3 py-4 text-base font-normal text-gray-700 bg-gray-200 rounded transition ease-in-out m-0 focus:text-gray-700   focus:outline-none"
                      id="exampleFormControlInput1"
                      placeholder="Password"
                      required="true"
                    />
                  </div>
                  <div class="text-center pt-1 mb-12 pb-1">
                    <button
                      class="inline-block px-6 py-5 text-white bg-primary font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-black hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3"
                      type="submit"
                      :disabled="loading"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      {{loading ? 'please wait...' : 'Login'}}
                    </button>
                    <a class="text-gray-500" href="#!">Forgot password?</a>
                  </div>
                  <div class="flex items-center justify-between pb-6">
                    <p class="mb-0 mr-2">Don't have an account?</p>
                    <button
                      type="button"
                      class="inline-block px-6 py-2 border-2 border-red-600 text-red-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      Request for registration
                    </button>
                  </div>
      </form>
      </div>
    </div>
</template>

<script>
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
export default {
    setup () {
        
      const username = ref('')
      const password = ref('')
      const loading = ref(false)

      const router = useRouter()
      const store = useStore()

      const login = () => {
        loading.value = true
        setTimeout(()=> {
          store.dispatch('login', {username: username.value})
          loading.value = false
          router.push({name: 'home'})
        }, 3000)
      }
        return {
          username,
          password,
          login,
          loading
        }
    }
}
</script>

<style lang="scss" scoped>

</style>