import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    searchResults: null,
    user: null,
  },
  mutations: {
    setSearchResults(state, payload) {
      state.searchResults = payload
    },
    SET_USER_DETAILS(state, payload){
     setTimeout(()=>{
      state.user = payload
      localStorage.setItem('user', payload)
      
     },2000)
    }

  },
  actions: {
    async search({commit}, query) {
      await axios.get(`volumes?q=${query}&key=AIzaSyDqMUA28lvwLk6x6FZL56qSwyipEhPjubA`)
        .then(response => {
          commit('setSearchResults', response.data)
        })
    },
    login({commit}, userdetails) {
      commit('SET_USER_DETAILS', userdetails)
    }
  },
  getters: {
    searchResults(state) {
      return state.searchResults
    },
    loggedIn(state) {
      return !!state.user
    }
  },
  modules: {
  }
})
