<template>
  <Navbar />
  <router-view/>
  <Footer/>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from './components/Footer.vue';
export default {
  components: {
    Footer,
    Navbar,
  }
}
</script>

