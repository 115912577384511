<template>
   <div className="fixed lg:p-40  inset-0 bg-black bg-opacity-30 backdrop-blur-md flex justify-center items-center">
    <div className="bg-white lg:my-40 p-2 px-5 lg:px-20 bac rounded-md lg:w-1/2 w-[90vw] h-[98vh] overflow-y-auto ">
          {{article?.bibjson.title}}
    </div>
  </div>
</template>

<script>
  import { onMounted } from 'vue';
export default {
  props: ['article'],
  setup (props, {emit}) {
    onMounted(()=>{
      console.log(props)
    })
    const closeModal = () => {
      emit('closeModal')
    }
    return {
      closeModal,
    }
  }
}
</script>

<style lang="scss" scoped>

</style>