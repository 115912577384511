<template>
 
   <div class="py-20 flex justify-center mx-auto  md:px-10 w-11/12 min-h-screen bg-[#dae4ea]">
    <div class="w-96 my-12 h-auto bg-[#f2f9fb] transition-all rounded-lg  md:w-full p-4">
        <div class="relative">
            <input v-model="query" class="w-full h-12 text-sm outline-none border mt-3 rounded-lg transition-all pl-7 pr-20 focus:border-blue-600" type="text" placeholder="Search  for E-resources here">
            <i class="absolute top-7 text-[#bfc6cd] left-2 fa fa-search"></i>
            <button @click="search" class="absolute right-2 rounded-lg cursor-pointer transition-all hover:bg-blue-900 top-4 h-10 w-16 bg-secondary text-white text-sm">Search</button>
        </div>
        <div class="flex mt-3 justify-between items-center">
            <p class="text-[#545968] text-sm font-semibold">Showing {{totalResults}} results</p>
            <div>
                <div class="flex items-center gap-1">
                    <p class="text-xs mt-1 ">Sort by</p>
                    <select class="outline-none cursor-pointer h-6 w-20  text-sm font-semibold ">
                        <option>Date</option>
                        <option>Time</option>
                        <option>Length</option>
                        <option>None</option>
                    </select>
                </div>
            </div>
        </div>
           <div class="w-full">
        
    </div>
        <!-- <Skeletor v-if="loading" :size="50" width="100%" height="100"/> -->
        <div v-if="loading" class="w-full h-52">
            <div class="flex flex-col">
                <div v-for="(i,index) in 10" :key="index" class="my-10">
                    <skeleton-loader :count="4" width="5%" height="80" class="rounded-md"  :theme="theme" />
                    <skeleton-loader :count="4" width="40%" height="20"  :theme="theme" />
                    <skeleton-loader :count="4" width="50%" height="20" :theme="theme" />
                    <skeleton-loader :count="4"  width="70%" height="20" :theme="theme" />
                </div>
            </div>
        </div>
        <div v-else v-for="(book, index) in booksData" :key="index" class="md:flex md:justify-center md:flex-wrap gap-10 ">
            <div @click="handleShowModal(book) " class="w-full h-84 p-3 border bg-white hover:bg-zinc-200 cursor-pointer mt-5 rounded-lg">
                <div v-if="booksData.length > 0" class="flex items-center gap-5">
                    <img src="../assets/folder.png" class="rounded-md h-20 w-20">
                    <div>
                        <p class="text-lg font-semibold  my-5"><b>Title:</b> {{book.bibjson.title}} </p>
                        <p class="text-pink-500 font-semibold text-sm "><b>Authors:</b><span v-for="(i, index) in 5" :key="index">{{book.bibjson.author[0].name}}</span> </p>
                        <p class="text-sm mt-3 text-pink-500"><b>Year: </b>{{book.bibjson.year}} </p>
                        <p v-if="book.bibjson.link.length > 0" class="text-sm mt-3 text-pink-500"><b>Full text: </b><a class="hover:text-blue-700" :href="book.bibjson.link[0].url">{{book.bibjson.link[0].url}}</a> </p>
                        <div class="flex justify-between items-center mt-6">
                        </div>
                    </div>
                </div>
                
            </div>  
        </div>
        <ArticleModal v-if="showModal" :article="articleToShow" />

        
    </div>
</div>
</template>

<script>

import {onMounted, computed, ref} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Skeletor } from 'vue-skeletor';
import axios from 'axios';
import SearchCard from '@/components/SearchCard'
import SkeletonLoader from '@/components/Skeleton'
import ArticleModal from '@/components/ArticleModal'
export default {
    components: { Skeletor, SkeletonLoader, ArticleModal },
    setup () {
        const route = useRoute()
        const store = useStore()
        const booksData = ref([])
        const query = ref('')
        const loading = ref(true)
        const totalResults = ref(0)
        let theme = ref('light')
        const articleToShow = ref({})
        const showModal = ref(false)
        onMounted(async ()=> {
            
            axios.get(`https://doaj.org/api/search/articles/${route.params.query}?page=1&pageSize=1000`)
                .then(response => {
                    loading.value = false
                    booksData.value =  response.data.results
                    totalResults.value = response.data.total
                    console.log(booksData.value)
                })
                .catch(err=> console.log('error: ' + err.message))
        })
        function search() {
            loading.value = true
            booksData.value =  {}
            axios.get(`https://doaj.org/api/search/articles/${query.value}?page=1&pageSize=1000`)
                .then(response => {
                    booksData.value =  response.data.results
                    loading.value = false
                     totalResults.value = response.data.total
                    console.log(response.data)
                })
                .catch(err=> console.log('error: ' + err.message))
        }
        const handleShowModal = (book) => {
            console.log(book)
            articleToShow.value= book
            showModal.value = true
        }
        return {
            booksData,
            search,
            query,
            loading,
            theme,
            totalResults,
            handleShowModal,
            showModal,
            articleToShow,
        }
    }
}
</script>

<style lang="scss" scoped>

</style>