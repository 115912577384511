import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import axios from 'axios'
// import cors from 'cors'
import 'vue-skeletor/dist/vue-skeletor.css';
axios.defaults.baseURL = "https://www.googleapis.com/books/v1/"
axios.defaults.headers.get['Content-Type'] ='application/json;charset=utf-8';
axios.interceptors.response.use(
  function (config) {
    setTimeout(() => console.log("Request taking too long"), 10000)    
    return config
  },
)

axios.interceptors.request.use(
  function (config) {
    setTimeout(() => console.log("Request taking too long"), 10000)
    return config
  }
)
createApp(App).use(store).use(router).mount('#app')
